import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
	Box,
	Container,
	Grid,
	Typography,
	Button,
	Stack,
	IconButton,
	Link,
} from "@mui/material";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import AdBanner from "../components/AdBanner";
import ChallengeItem from "../components/ChallengeItem";
import {
	getPackById,
	getPackByPackName,
	getChallengesByPackId,
	getOtherChallenges,
} from "../helper/program.helper";
import { logEngagement } from "../helper/log.helper";
import QuizSeries from "../components/QuizSeries";
import ISISection from "../components/ISISection";
import LoadingSpinner from "../components/LoadingSpinner";

function decodeHtml(html) {
	var txt = document.createElement("textarea");
	txt.innerHTML = html;
	return txt.value;
}

const ChallengePack = () => {
	const { packName } = useParams();
	const location = useLocation();

	const searchParams = new URLSearchParams(location.search);
	const packId = searchParams.get("packid");
	const npi = searchParams.get("npi");
	const utmSource = searchParams.get("utm_source");
	const utmMedium = searchParams.get("utm_medium");

	const settings = useSelector((state) => state.siteSettings);
	const ipAddress = useSelector((state) => state.ipAddress);

	const [open, setOpen] = useState(false);
	const [response, setResponse] = useState({
		status: false,
		message: "",
	});
	const [challengePack, setChallengePack] = useState();
	const [challenges, setChallenges] = useState([]);
	const [loading, setLoading] = useState(true);
	const [isAnswered, setIsAnswered] = useState(false);


	useEffect(() => {
		if (ipAddress !== "" && challengePack) {
			const payload = {
				npi: npi,
				ipAddress: ipAddress,
				engagementRefId: "Challenge Pack",
				engagementSecRefId: challengePack.id,
				engagementType: "Page View",
				utmSource: utmSource,
				utmMedium: utmMedium,
				timeToComplete: "",
				gameResult: "",
			};
			logEngagement(payload);
		}
	}, [ipAddress, challengePack]);

	useEffect(() => {
		if (packId) {
			getPackById(packId)
				.then(({ pack }) => {
					setChallengePack(pack);
					if (pack.challenges) {
						let array = pack.challenges.split(",").filter((c) => c && c !== "");
						getOtherChallenges(array).then(({ challenges }) => {
							setChallenges(challenges);
						});
					}
				})
				.catch((errorMessage) => {
					setOpen(true);
					setResponse({
						status: false,
						message: errorMessage,
					});
				}).finally(() => {
					setLoading(false);
				});
		} else {
			const decodePackName = packName?.replace(/-/g, " ");
			getPackByPackName(decodePackName)
				.then(({ pack }) => {
					setChallengePack(pack);
					setLoading(false);
					getChallengesByPackId(pack.id).then((res) => {
						setChallenges(res.challenges);
					});
				})
				.catch((errorMessage) => {
					setOpen(true);
					setLoading(false);
					setResponse({
						status: false,
						message: errorMessage,
					});
				}).finally(() => {
					setLoading(false);
				});
		}
	}, [packId]);


	if (loading) {
		return <LoadingSpinner message={`Loading Challenge Pack...`} />;
	}


	return (
		<>
			{challengePack &&
				// (challengePack.is_active === 0 ? (
				(challengePack.is_active === "1" &&
					challengePack.is_dedicated_page === "1" ? (
					<>
						<Container
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								gap: { xs: 0, sm: 3 },
								py: { xs: 0 },
								px: { xs: 0 },
								textAlign: { sm: "center", md: "left" },
								maxWidth: "unset !important",
							}}
							id="main"
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									gap: { xs: 2, sm: 3 },
									py: { xs: 0 },
									px: { xs: 0 },
									textAlign: { sm: "center", md: "left" },
									width: "100%",
								}}
							>
								<Box
									width={"100%"}
									px={6}
									py={2}
									borderBottom={"1px solid #707070"}
								>
									<Box
										component={"img"}
										width={{ sm: "unset", xs: "60%" }}
										src={
											settings && settings.site_logo !== ""
												? process.env.REACT_APP_API_IMAGE_URL +
												settings.site_logo
												: "/assets/images/POCN_Logo_TM_285x120.png"
										}
									></Box>
								</Box>
								<Box
									width="100%"
									px={{ sm: 6, xs: 2 }}
									display="flex"
									flexDirection="column"
									gap={{ sm: 3, xs: 2 }}
									alignItems="center"
								>
									{challengePack &&
										(challengePack.pi_link || challengePack.isi_link) && (
											<Box
												display={{ sm: "flex", xs: "flex" }}
												flexDirection={{ md: "row", xs: "column" }}
												justifyContent={{ sm: "center" }}
											>
												{challengePack.pi_link && (
													<Link
														href={challengePack.pi_link}
														target="_blank"
														sx={{
															color: `${challengePack &&
																challengePack.pl_color &&
																challengePack.pl_color !== ""
																? challengePack.pl_color
																: "#4933DA"
																}`,
															fontWeight: 700,
															fontFamily: "'Poppins'",
															pr: { md: 1, xs: 0 },
															borderRight: {
																md: `1px solid ${challengePack &&
																	challengePack.pl_color &&
																	challengePack.pl_color !== ""
																	? challengePack.pl_color
																	: "#4933DA"
																	}`,
																sm: "none",
															},
															textDecoration: "underline",
														}}
													>
														US Full Prescribing Information
													</Link>
												)}
												{challengePack.isi_link && (
													<Link
														href={challengePack.isi_link}
														target="_blank"
														sx={{
															color: `${challengePack &&
																challengePack.pl_color &&
																challengePack.pl_color !== ""
																? challengePack.pl_color
																: "#4933DA"
																}`,
															fontWeight: 700,
															fontFamily: "'Poppins'",
															ml: { md: 1, xs: 0 },
															textDecoration: "underline",
														}}
													>
														Important Safety Information
													</Link>
												)}
											</Box>
										)}
									{challengePack && challengePack.brand_logo && (
										<Box
											component={"img"}
											src={
												process.env.REACT_APP_API_IMAGE_URL +
												challengePack.brand_logo
											}
											width={{ sm: "unset", xs: "60%" }}
											sx={{ mt: -1 }}
										></Box>
									)}
									{challengePack && challengePack.indication_info && (
										<Box
											border={`1px solid ${challengePack.indication_color
												? challengePack.indication_color
												: "#4933DA"
												}`}
											px={2}
											py={2}
											borderRadius={2}
										>
											<Typography
												sx={{
													color: "#4933DA",
													textAlign: "left",
													px: { sm: 6, xs: 0 },
												}}
												dangerouslySetInnerHTML={{
													__html: decodeHtml(challengePack.indication_info),
												}}
											/>
										</Box>
									)}
									{/* {challengePack &&
                    challengePack.banner_ad_id &&
                    challengePack.banner_ad_id !== 0 && <AdBanner />} */}
								</Box>
							</Box>
							{/* 
              {challengePack &&
                challengePack.banner_ad_id &&
                challengePack.banner_ad_id !== 0 && <AdBanner />} */}
							<Box
								sx={{
									px: { xs: 0 },
									display: "flex",
									flexDirection: "column",
									width: "100%",
									mb: 8,
								}}
							>
								{/* {challengePack &&
                  challengePack.show_description === "1" &&
                  challengePack.description && (
                    <Typography
                      sx={{
                        fontSize: "32px",
                        fontWeight: 700,
                        color: "#333",
                        textAlign: "center",
                        px: { xs: 6 },
                      }}
                      dangerouslySetInnerHTML={{
                        __html: decodeHtml(challengePack.description),
                      }}
                    />
                  )} */}
								<QuizSeries
									challenges={challenges}
									resultMessage1={challengePack.quiz_result_message_1}
									resultMessage2={challengePack.quiz_result_message_2}
									packId={challengePack.id}
									moreUrl={challengePack.more_url}
									onAnswered={() => setIsAnswered(true)}
									packDescription={
										challengePack && challengePack.show_description === "1"
											? challengePack.description
											: ""
									}
								/>
								{/* <Box
            sx={{
              px: { xs: 6 },
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: { md: "left", sm: "center" },
                gap: 4,
                flexWrap: "wrap",
                mt: 3,
              }}
            >
              <Grid container spacing={4}>
                {challenges.map((challenge, index) => {
                  return (
                    <Grid item md={4} sm={6} xs={12}>
                      <ChallengeItem
                        name={challenge.name}
                        link={`${challenge.program_id ? "/program" : ""}${
                          challenge.challenge_pack_id ? "/pack" : ""
                        }/challenge/${
                          challenge.short_url && challenge.short_url !== ""
                            ? challenge.short_url
                            : challenge.name
                        }?challengeid=${challenge.id}`}
                        image={
                          challenge.image && challenge.image !== ""
                            ? process.env.REACT_APP_API_IMAGE_URL +
                              challenge.image
                            : null
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Box> */}
							</Box>
						</Container>
						{challengePack && challengePack.optional_content && isAnswered && (
							<Box
								width="100%"
								px="4%"
								pb={12}
								textAlign="left"
								dangerouslySetInnerHTML={{
									__html: decodeHtml(challengePack.optional_content),
								}}
							></Box>
						)}
						{challengePack && challengePack.isi && (
							<ISISection
								isiText={challengePack.isi}
								isSticky={challengePack.stiky_isi === "1"}
							/>
						)}
						<Box
							display="flex"
							flexDirection="column"
							alignItems="center"
							gap={2}
							width="100%"
							px={{ md: 6, sm: 4, xs: 3 }}
							pt={0}
							pb={9}
							mt={5}
						>
							{challengePack && challengePack.company_logo && (
								<Box
									component="img"
									src={
										process.env.REACT_APP_API_IMAGE_URL +
										challengePack.company_logo
									}
									alt="logo of sitemark"
									width={{ sm: "unset", xs: "50%" }}
								></Box>
							)}
							{challengePack && challengePack.company_info && (
								<Typography
									sx={{
										textAlign: "center",
										width: { md: "50%", sm: "70%", xs: "100%" },
									}}
									dangerouslySetInnerHTML={{
										__html: decodeHtml(challengePack.company_info),
									}}
								></Typography>
							)}
							{challengePack &&
								(challengePack.legal_notice_link || challengePack.pp_link) && (
									<Box>
										{challengePack.legal_notice_link && (
											<Link
												href={challengePack.legal_notice_link}
												target="_blank"
												sx={{
													color: challengePack.ln_pp_color
														? challengePack.ln_pp_color
														: "#DA00B5",
													pr: 2,
													borderRight: `${challengePack.pp_link ? "1px solid #4933DA" : "none"
														}`,
												}}
											>
												Legal Notice
											</Link>
										)}
										{challengePack.pp_link && (
											<Link
												href={challengePack.pp_link}
												target="_blank"
												sx={{
													color: challengePack.ln_pp_color
														? challengePack.ln_pp_color
														: "#DA00B5",
													ml: 2,
												}}
											>
												Privacy Policy
											</Link>
										)}
									</Box>
								)}
						</Box>
						<Container
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								gap: { xs: 4, sm: 8 },
								mt: 5,
								py: { xs: 3, sm: 4 },
								px: { xs: 6 },
								textAlign: { sm: "center", md: "left" },
								bgcolor: "#fff",
								borderTop: "1px solid #707070",
								maxWidth: "unset !important",
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: { md: "row", sm: "column", xs: "column" },
									gap: { md: 5, sm: 4, xs: 4 },
									width: "100%",
									justifyContent: "space-between",
									alignItems: "center",
								}}
							>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: {
											md: "flex-start",
											sm: "center",
											xs: "center",
										},
										gap: 4,
										width: { md: "45%", sm: "100%" },
									}}
								>
									<Box
										component="img"
										src={`${settings && settings.footer_logo
											? process.env.REACT_APP_API_IMAGE_URL +
											settings.footer_logo
											: "/assets/images/POCN_Logo-White_TM_285x120.png"
											}`}
										alt="logo of sitemark"
									></Box>
								</Box>
								<Box
									sx={{
										display: "flex",
										flexDirection: { md: "row", sm: "column", xs: "column" },
										gap: { md: 5, sm: 3, xs: 1 },
										width: { md: "50%", sm: "100%", xs: "100%" },
										justifyContent: "space-between",
									}}
								>
									<Box display="flex" flexDirection="column" gap={1}>
										<Link
											href={
												settings && settings.privacy_policy_link
													? settings.privacy_policy_link
													: "#"
											}
											target="_blank"
											sx={{ color: "#001232" }}
										>
											Privacy Policy
										</Link>
										<Link
											href={
												settings && settings.terms_link
													? settings.terms_link
													: "#"
											}
											target="_blank"
											sx={{ color: "#001232" }}
										>
											Terms of Use
										</Link>
									</Box>
									<Box display="flex" flexDirection="column" gap={1}>
										<Link
											href={
												settings && settings.contact_us_link
													? settings.contact_us_link
													: "#"
											}
											target="_blank"
											sx={{ color: "#001232" }}
										>
											Contact Us
										</Link>
										<Link
											href={
												settings && settings.learning_hub_link
													? settings.learning_hub_link
													: "#"
											}
											target="_blank"
											sx={{ color: "#001232" }}
										>
											Learning Hub
										</Link>
									</Box>
									<Box display="flex" flexDirection="column" gap={1}>
										<Link
											href={
												settings && settings.pocn_plus_link
													? settings.pocn_plus_link
													: "#"
											}
											target="_blank"
											sx={{ color: "#001232" }}
										>
											POCN+
										</Link>
										<Link
											href={
												settings && settings.mentor_program_link
													? settings.mentor_program_link
													: "#"
											}
											target="_blank"
											sx={{ color: "#001232" }}
										>
											Mentor Program
										</Link>
									</Box>
									<Box display="flex" flexDirection="column" gap={1}>
										<Link
											href={`${settings && settings.be_a_leader_link
												? settings.be_a_leader_link
												: "https://www.pocn.com/ambassador-program/"
												}`}
											target="_blank"
											sx={{ color: "#001232" }}
										>
											Be a Leader
										</Link>
									</Box>
								</Box>
							</Box>
							<Box
								width="100%"
								py={3}
								display="flex"
								flexDirection={{ md: "row", xs: "column-reverse" }}
								justifyContent="space-between"
								alignItems="center"
								borderTop="1px solid #707070"
							>
								<Typography
									sx={{
										fontSize: { md: "17px", sm: "16px", xs: "14px" },
										fontWeight: 400,
										color: "#414042",
										textAlign: { sm: "left", xs: "center" },
									}}
								>
									{settings && settings.copyright_text
										? settings.copyright_text
										: "© 2024 Point Of Care Network, LLC. All Rights Reserved."}
								</Typography>
								<Stack
									direction="row"
									justifyContent="left"
									spacing={1}
									useFlexGap
									sx={{
										color: "text.secondary",
									}}
								>
									<IconButton
										color="inherit"
										href={`${settings && settings.facebook_link
											? settings.facebook_link
											: "https://facebook.com/POCNInc"
											}`}
										target="_blank"
										aria-label="facebook"
										sx={{ alignSelf: "center" }}
									>
										<FacebookRoundedIcon fontSize="medium" />
									</IconButton>
									<IconButton
										color="inherit"
										href={`${settings && settings.twitter_link
											? settings.twitter_link
											: "https://twitter.com/POCNInc"
											}`}
										target="_blank"
										aria-label="twitter"
										sx={{ alignSelf: "center" }}
									>
										<TwitterIcon fontSize="medium" />
									</IconButton>
									<IconButton
										color="inherit"
										href={`${settings && settings.linkedin_link
											? settings.linkedin_link
											: "https://www.linkedin.com/company/pocn/"
											}`}
										target="_blank"
										aria-label="linkedin"
										sx={{ alignSelf: "center" }}
									>
										<LinkedInIcon fontSize="medium" />
									</IconButton>
									<IconButton
										color="inherit"
										href={`${settings && settings.instagram_link
											? settings.instagram_link
											: "https://www.instagram.com/pocninc/"
											}`}
										target="_blank"
										aria-label="instagram"
										sx={{ alignSelf: "center" }}
									>
										<InstagramIcon fontSize="medium" />
									</IconButton>
								</Stack>
							</Box>
						</Container>
					</>
				) : (
					<Container
						component="main"
						maxWidth="xs"
						sx={{
							mt: 8,
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Box sx={{ my: 5 }}>
							<Typography variant="h4" component="h1" gutterBottom>
								Inactive or Non-Dedicated Page
							</Typography>
							<Typography variant="subtitle1">
								This page is currently under construction or not available for
								public view.
							</Typography>
						</Box>
					</Container>
				))}
		</>
	);
};

export default ChallengePack;
