import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Container, Typography, Button, Stack } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import QuizCorrect from "./QuizCorrect";
import { sendQuizAnswer, saveQuizResult } from "../helper/program.helper";
import { logEngagement } from "../helper/log.helper";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";

function decodeHtml(html) {
	var txt = document.createElement("textarea");
	txt.innerHTML = html;
	return txt.value;
}

export default function QuizSeries({
	challenges,
	resultMessage1,
	resultMessage2,
	packId,
	moreUrl,
	packDescription,
	onAnswered,
}) {
	const [step, setStep] = useState(0);
	const [quizzes, setQuizzes] = useState([]);
	const [answered, setAnswered] = useState(false);
	const [isCorrect, setIsCorrect] = useState(false);
	const [answerStates, setAnswerStates] = useState([]);
	const [isLast, setIsLast] = useState(false);
	const [incorrectResult, setIncorrectResult] = useState(false);
	const [timeTaken, setTimeTaken] = useState(0);
	const [answerChoice, setAnswerChoice] = useState("");
	const [correctAnswer, setCorrectAnswer] = useState("");
	const [correctAnswersCount, setCorrectAnswerCount] = useState(0);
	const [resultPercent, setResultPercent] = useState(0);
	const [packResultPercent, setPackResultPercent] = useState(0);

	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const npi = searchParams.get("npi");
	const utmSource = searchParams.get("utm_source");
	const utmMedium = searchParams.get("utm_medium");

	const ipAddress = useSelector((state) => state.ipAddress);

	useEffect(() => {
		const interval = setInterval(() => {
			setTimeTaken((prevTime) => prevTime + 1);
		}, 1000);

		const getAllQuizzesWithDetails = (challenges) => {
			let allQuizzes = [];
			for (let challenge of challenges) {
				if (challenge.quizzes) {
					// Parse the quizzes, reverse them, and then create a new object for each quiz
					const quizzes = JSON.parse(challenge.quizzes).reverse();
					quizzes.forEach((quiz, index) => {
						allQuizzes.push({
							quiz: quiz,
							id: challenge.id,
							name: challenge.name,
							description: challenge.description,
							image: challenge.image,
							challenge_bg_image: challenge.challenge_bg_image,
							success_message: challenge.success_message,
							success_message_image: challenge.success_message_image,
							more_url: challenge.more_url,
							display_results: challenge.display_results,
							incorrect_message: challenge.incorrect_message,
							step: quizzes.length - (index + 1),
						});
					});
				}
			}

			return allQuizzes;
		};

		setQuizzes(getAllQuizzesWithDetails(challenges));

		return () => clearInterval(interval);
	}, [challenges]);

	const handleSubmitAnswer = (id, quizStep) => {
		sendQuizAnswer(id, answerChoice, quizStep, ipAddress).then((res) => {
			setAnswered(true);
			onAnswered();
			setIsCorrect(res.evaluate);
			setCorrectAnswer(res.correctAnswer);
			setAnswerStates([...answerStates, res.evaluate]);

			if (res.evaluate) {
				setCorrectAnswerCount(correctAnswersCount + 1);
				setResultPercent(res.percent);
			}

			if (quizzes.length - 1 === step) {
				saveQuizResult(
					packId,
					correctAnswersCount / quizzes.length >= 0.5,
					ipAddress,
					correctAnswersCount
				).then((res) => {
					setPackResultPercent(res.percent);
				});
			}

			let payload = {
				npi: npi,
				ipAddress: ipAddress,
				engagementRefId: "Challenge",
				engagementSecRefId: id,
				engagementType: "Challenge Completed",
				utmSource: utmSource,
				utmMedium: utmMedium,
				timeToComplete: timeTaken,
				gameResult: res.evaluate ? "Success" : "Failure",
			};
			logEngagement(payload);

			payload = {
				npi: npi,
				ipAddress: ipAddress,
				engagementRefId: "Message Page",
				engagementSecRefId: id,
				engagementType: "Page View",
				utmSource: utmSource,
				utmMedium: utmMedium,
				timeToComplete: "",
				gameResult: "",
			};
			logEngagement(payload);
		});
	};

	const handleNextCorrect = () => {
		if (quizzes.length - 1 === step) {
			setIsLast(quizzes.length - 1 === step);
			setIncorrectResult(true);

			let payload = {
				npi: npi,
				ipAddress: ipAddress,
				engagementRefId: "Results Page",
				engagementSecRefId: packId,
				engagementType: "Page View",
				utmSource: utmSource,
				utmMedium: utmMedium,
				timeToComplete: "",
				gameResult: "",
			};
			logEngagement(payload);
		} else {
			setAnswered(false);
			setAnswerChoice("");
			setIsCorrect(false);
			setStep(step + 1);
		}
	};

	const handleChangeChoice = (event) => {
		setAnswerChoice(event.target.value);
	};

	const handleNavigation = (url) => {
		if (url) {
			let payload = {
				npi: npi,
				ipAddress: ipAddress,
				engagementRefId: "Message Page",
				engagementSecRefId: "",
				engagementType: "Clicked CTA",
				utmSource: utmSource,
				utmMedium: utmMedium,
				timeToComplete: "",
				gameResult: "",
			};
			logEngagement(payload);

			if (!url.startsWith("http://") && !url.startsWith("https://")) {
				url = "http://" + url; // Default to http if no protocol is specified
			}

			window.open(url, "_blank", "noopener,noreferrer");
		}
	};

	return quizzes.map((quiz, index) => {
		return (
			step === index && (
				<Box
					display="flex"
					flexDirection="column"
					gap={2}
					width="100%"
					px={{ md: 6, xs: 3 }}
					pt={{ md: 10 }}
					pb={{ md: 5, xs: 3 }}
					sx={{
						backgroundImage: `url('${quiz.challenge_bg_image
								? process.env.REACT_APP_API_IMAGE_URL + quiz.challenge_bg_image
								: "/assets/images/default challenge bg.png"
							}')`,
						backgroundSize: { md: "cover", xs: "contain" },
						backgroundRepeat: "no-repeat",
						bgcolor: "#001232",
					}}
				>
					<Container
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: 2,
							mt: { md: 14, sm: 9, xs: 9 },
							px: { xs: 0 },
						}}
					>
						<Typography
							sx={{
								fontSize: { md: "42px", sm: "38px", xs: "24px" },
								fontWeight: 700,
								color: "#FFFFFF",
								textAlign: "center",
							}}
						>
							{packDescription ? (
								<div
									className="pack-description"
									dangerouslySetInnerHTML={{
										__html: decodeHtml(packDescription),
									}}
								/>
							) : (
								`Take the ${quiz.name} Challenge!`
							)}
						</Typography>
						<Box
							sx={{
								px: { md: 7, sm: 5, xs: 2 },
								py: { md: 4, sm: 3, xs: 2 },
								border: "2px solid #ccc",
								borderRadius: { md: 10, sm: 8, xs: 6 },
								bgcolor: "#fff",
								mt: { sm: 3, xs: 1 },
							}}
						>
							{isCorrect || incorrectResult ? (
								<QuizCorrect
									challenegId={quiz.id}
									packId={packId}
									isLast={isLast}
									isSingle={quizzes.length === 1}
									isDisplayStats={true}
									successMessage={quiz.success_message}
									successImage={quiz.success_message_image}
									resultMessage1={resultMessage1}
									resultMessage2={resultMessage2}
									correctCount={correctAnswersCount}
									quizCount={quizzes.length}
									moreUrl={moreUrl}
									step={step + 1}
									quizzes={quizzes}
									answerStates={answerStates}
									resultPercent={resultPercent}
									packResultPercent={packResultPercent}
									className={`quiz ${answered && isCorrect ? "fade-in" : "fade-out"
										}`}
									onNext={handleNextCorrect}
								/>
							) : (
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										flexDirection: "column",
										alignItems: "center",
										gap: { md: 4, sm: 3, xs: 1 },
									}}
								// className={`quiz ${step === index ? "fade-in" : "fade-out"}`}
								>
									<Container
										sx={{
											px: { xs: 0 },
											display: "flex",
											flexDirection: "column",
											justifyContent: "space-between",
											gap: 2,
											pb: { md: 6, sm: 4, xs: 2 },
											borderBottom: "2px solid #001232",
										}}
									>
										{quizzes.length > 1 && (
											<Typography
												sx={{
													fontSize: "18px",
													fontWeight: 700,
													color: "#333",
													textAlign: "left",
												}}
											>
												Question {step + 1} of {quizzes.length}
											</Typography>
										)}

										{answered && (
											<Box
												sx={{
													textAlign: "center",
													position: "relative",
												}}
											>
												<Typography
													sx={{
														fontSize: { md: "45px", sm: "38px", xs: "28px" },
														fontWeight: 700,
														color: "#FF0000",
													}}
												>
													INCORRECT!
												</Typography>
											</Box>
										)}
										<Box
											display="flex"
											flexDirection={{
												md: "row",
												sm: "column",
												xs: "column",
											}}
											justifyContent="space-between"
											gap={{ md: 7, sm: 5, xs: 2 }}
										>
											<Box
												sx={{
													mt: 1,
													width: { md: "47%", sm: "100%", xs: "100%" },
												}}
											>
												<Box
													component="img"
													src={`${quiz.image
															? process.env.REACT_APP_API_IMAGE_URL + quiz.image
															: "/assets/images/Treating-Obesity.jpg"
														}`}
													sx={{ width: "100%" }}
												></Box>
											</Box>
											<Box
												sx={{
													width: { md: "50%", sm: "100%", xs: "100%" },
												}}
											>
												<Typography
													sx={{
														fontSize: { md: "24px", sm: "22px", xs: "20px" },
														fontWeight: 600,
														color: "#71C9F0",
														textAlign: { md: "left", xs: "left" },
													}}
													dangerouslySetInnerHTML={{
														__html: decodeHtml(quiz.quiz.quizQuestion),
													}}
												></Typography>
												<FormControl
													sx={{ mt: { md: 2, xs: 1 }, width: "100%", ml: -2 }}
												>
													<RadioGroup
														aria-labelledby="demo-radio-buttons-group-label"
														defaultValue="female"
														name="radio-buttons-group"
														value={answerChoice}
														onChange={handleChangeChoice}
														sx={{ gap: { sm: 1, xs: 0 } }}
													>
														{quiz.quiz.answerChoice1 && (
															<Stack
																flexDirection="row"
																justifyContent="space-between"
																alignItems="center"
																width="100%"
																px={2}
																bgcolor={`${answered &&
																		answerChoice === quiz.quiz.answerChoice1
																		? "#ff4569"
																		: ""
																	}`}
																borderRadius={2}
															>
																<FormControlLabel
																	value={quiz.quiz.answerChoice1}
																	control={<Radio />}
																	label={quiz.quiz.answerChoice1}
																	disabled={
																		answered &&
																		answerChoice !== quiz.quiz.answerChoice1
																	}
																	sx={{
																		"& .MuiFormControlLabel-label": {
																			fontSize: {
																				md: "22px",
																				sm: "20px",
																				xs: "18px",
																			},
																			fontWeight: 600,
																			color:
																				answered &&
																					answerChoice === quiz.quiz.answerChoice1
																					? "#fff"
																					: "#001232",
																		},
																		"& .Mui-checked": {
																			color:
																				answered &&
																					answerChoice === quiz.quiz.answerChoice1
																					? "#fff"
																					: "#0959AA",
																		},
																	}}
																/>
																{answered &&
																	answerChoice === quiz.quiz.answerChoice1 && (
																		<CloseIcon
																			sx={{
																				color: "#fff",
																				fontSize: "28px",
																				fontWeight: 700,
																			}}
																		/>
																	)}
																{answered &&
																	quiz.quiz.answerChoice1 === correctAnswer && (
																		<DoneIcon
																			sx={{
																				color: "green",
																				fontSize: "28px",
																			}}
																		/>
																	)}
															</Stack>
														)}
														{quiz.quiz.answerChoice2 && (
															<Stack
																flexDirection="row"
																justifyContent="space-between"
																alignItems="center"
																width="100%"
																px={2}
																bgcolor={`${answered &&
																		answerChoice === quiz.quiz.answerChoice2
																		? "#ff4569"
																		: ""
																	}`}
																borderRadius={2}
															>
																<FormControlLabel
																	value={quiz.quiz.answerChoice2}
																	control={<Radio />}
																	label={quiz.quiz.answerChoice2}
																	sx={{
																		"& .MuiFormControlLabel-label": {
																			fontSize: {
																				md: "22px",
																				sm: "20px",
																				xs: "18px",
																			},
																			fontWeight: 600,
																			color:
																				answered &&
																					answerChoice === quiz.quiz.answerChoice2
																					? "#fff"
																					: "#001232",
																		},
																		"& .Mui-checked": {
																			color:
																				answered &&
																					answerChoice === quiz.quiz.answerChoice2
																					? "#fff"
																					: "#0959AA",
																		},
																	}}
																	disabled={
																		answered &&
																		answerChoice !== quiz.quiz.answerChoice2
																	}
																/>
																{answered &&
																	answerChoice === quiz.quiz.answerChoice2 && (
																		<CloseIcon
																			sx={{
																				color: "#fff",
																				fontSize: "28px",
																				fontWeight: 700,
																			}}
																		/>
																	)}
																{answered &&
																	quiz.quiz.answerChoice2 === correctAnswer && (
																		<DoneIcon
																			sx={{
																				color: "green",
																				fontSize: "28px",
																			}}
																		/>
																	)}
															</Stack>
														)}
														{quiz.quiz.answerChoice3 && (
															<Stack
																flexDirection="row"
																justifyContent="space-between"
																alignItems="center"
																width="100%"
																px={2}
																bgcolor={`${answered &&
																		answerChoice === quiz.quiz.answerChoice3
																		? "#ff4569"
																		: ""
																	}`}
																borderRadius={2}
															>
																<FormControlLabel
																	value={quiz.quiz.answerChoice3}
																	control={<Radio />}
																	label={quiz.quiz.answerChoice3}
																	disabled={
																		answered &&
																		answerChoice !== quiz.quiz.answerChoice3
																	}
																	sx={{
																		"& .MuiFormControlLabel-label": {
																			fontSize: {
																				md: "22px",
																				sm: "20px",
																				xs: "18px",
																			},
																			fontWeight: 600,
																			color:
																				answered &&
																					answerChoice === quiz.quiz.answerChoice3
																					? "#fff"
																					: "#001232",
																		},
																		"& .Mui-checked": {
																			color:
																				answered &&
																					answerChoice === quiz.quiz.answerChoice3
																					? "#fff"
																					: "#0959AA",
																		},
																	}}
																/>
																{answered &&
																	answerChoice === quiz.quiz.answerChoice3 && (
																		<CloseIcon
																			sx={{
																				color: "#fff",
																				fontSize: "28px",
																				fontWeight: 700,
																			}}
																		/>
																	)}
																{answered &&
																	quiz.quiz.answerChoice3 === correctAnswer && (
																		<DoneIcon
																			sx={{
																				color: "green",
																				fontSize: "28px",
																			}}
																		/>
																	)}
															</Stack>
														)}
														{quiz.quiz.answerChoice4 && (
															<Stack
																flexDirection="row"
																justifyContent="space-between"
																alignItems="center"
																width="100%"
																px={2}
																bgcolor={`${answered &&
																		answerChoice === quiz.quiz.answerChoice4
																		? "#ff4569"
																		: ""
																	}`}
																borderRadius={2}
															>
																<FormControlLabel
																	value={quiz.quiz.answerChoice4}
																	control={<Radio />}
																	label={quiz.quiz.answerChoice4}
																	disabled={
																		answered &&
																		answerChoice !== quiz.quiz.answerChoice4
																	}
																	sx={{
																		"& .MuiFormControlLabel-label": {
																			fontSize: {
																				md: "22px",
																				sm: "20px",
																				xs: "18px",
																			},
																			fontWeight: 600,
																			color:
																				answered &&
																					answerChoice === quiz.quiz.answerChoice4
																					? "#fff"
																					: "#001232",
																		},
																		"& .Mui-checked": {
																			color:
																				answered &&
																					answerChoice === quiz.quiz.answerChoice4
																					? "#fff"
																					: "#0959AA",
																		},
																	}}
																/>
																{answered &&
																	answerChoice === quiz.quiz.answerChoice4 && (
																		<CloseIcon
																			sx={{
																				color: "#fff",
																				fontSize: "28px",
																				fontWeight: 700,
																			}}
																		/>
																	)}
																{answered &&
																	quiz.quiz.answerChoice4 === correctAnswer && (
																		<DoneIcon
																			sx={{
																				color: "green",
																				fontSize: "28px",
																			}}
																		/>
																	)}
															</Stack>
														)}
														{quiz.quiz.answerChoice5 && (
															<Stack
																flexDirection="row"
																justifyContent="space-between"
																alignItems="center"
																width="100%"
																px={2}
																bgcolor={`${answered &&
																		answerChoice === quiz.quiz.answerChoice5
																		? "#ff4569"
																		: ""
																	}`}
																borderRadius={2}
															>
																<FormControlLabel
																	value={quiz.quiz.answerChoice5}
																	control={<Radio />}
																	label={quiz.quiz.answerChoice5}
																	disabled={
																		answered &&
																		answerChoice !== quiz.quiz.answerChoice5
																	}
																	sx={{
																		"& .MuiFormControlLabel-label": {
																			fontSize: {
																				md: "22px",
																				sm: "20px",
																				xs: "18px",
																			},
																			fontWeight: 600,
																			color:
																				answered &&
																					answerChoice === quiz.quiz.answerChoice5
																					? "#fff"
																					: "#001232",
																		},
																		"& .Mui-checked": {
																			color:
																				answered &&
																					answerChoice === quiz.quiz.answerChoice5
																					? "#fff"
																					: "#0959AA",
																		},
																	}}
																/>
																{answered &&
																	answerChoice === quiz.quiz.answerChoice5 && (
																		<CloseIcon
																			sx={{
																				color: "#fff",
																				fontSize: "28px",
																				fontWeight: 700,
																			}}
																		/>
																	)}
																{answered &&
																	quiz.quiz.answerChoice5 === correctAnswer && (
																		<DoneIcon
																			sx={{
																				color: "green",
																				fontSize: "28px",
																			}}
																		/>
																	)}
															</Stack>
														)}
													</RadioGroup>
												</FormControl>
											</Box>
										</Box>
										{answered && (
											<Box mt={2}>
												<Typography
													sx={{
														fontSize: "20px",
														fontWeight: 400,
														color: "#333",
														textAlign: "center",
													}}
													dangerouslySetInnerHTML={{
														__html:
															quiz.incorrect_message &&
																quiz.incorrect_message !== ""
																? decodeHtml(quiz.incorrect_message)
																: "Unfortunately you didn’t answer correctly but great news!",
													}}
												></Typography>
											</Box>
										)}
									</Container>
									{answered ? (
										step < quizzes.length - 1 ? (
											<Button
												variant="contained"
												size="medium"
												color="success"
												sx={{
													backgroundColor: "#A2D049",
													fontSize: { md: "20px", sm: "18px", xs: "16px" },
													fontWeight: 600,
													color: "#021637",
													width: "250px",
													py: 1,
													"&:hover": {
														bgcolor: "#bcf154",
													},
												}}
												onClick={handleNextCorrect}
											>
												Next
											</Button>
										) : (
											<Button
												variant="contained"
												size="medium"
												color="success"
												sx={{
													backgroundColor: "#A2D049",
													fontSize: { md: "20px", sm: "18px", xs: "16px" },
													fontWeight: 600,
													color: "#021637",
													width: "250px",
													py: 1,
													"&:hover": {
														bgcolor: "#bcf154",
													},
												}}
												onClick={() => {
													setIncorrectResult(true);
													setIsLast(true);
												}}
											>
												View Results
											</Button>
										)
									) : (
										<Button
											variant="contained"
											size="large"
											color="success"
											sx={{
												backgroundColor: "#A2D049",
												fontSize: { md: "20px", sm: "18px", xs: "16px" },
												fontWeight: 700,
												color: "#021637",
												"&:hover": {
													bgcolor: "#bcf154",
												},
											}}
											disabled={answerChoice === ""}
											onClick={() => handleSubmitAnswer(quiz.id, quiz.step)}
										>
											Submit Answer
										</Button>
									)}
								</Box>
							)}
						</Box>
					</Container>
				</Box>
			)
		);
	});
}
