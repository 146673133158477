import React from "react";
import { CircularProgress, Box, Typography } from "@mui/material";

const LoadingSpinner = ({ message }) => {
	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			position="fixed"
			top={0}
			left={0}
			width="100vw"
			height="100vh"
			sx={{
				backdropFilter: "blur(4px)",
				animation: "fadeIn 0.3s ease-in-out",
				"@keyframes fadeIn": {
					"0%": { opacity: 0 },
					"100%": { opacity: 1 },
				},
			}}
		>
			<Box textAlign="center">
				<CircularProgress size={60} thickness={4} />
				{message && (
					<Typography
						variant="h6"
						sx={{ mt: 2, fontWeight: 500, color: "#333" }}
					>
						{message}
					</Typography>
				)}
			</Box>
		</Box>
	);
};

export default LoadingSpinner;
