import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
	Container,
	Card,
	CardContent,
	Typography,
	Button,
	Box,
	CardMedia,
	TextField,
	CircularProgress,
	Fade,
	InputBase,
} from "@mui/material";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import { styled } from "@mui/system";
import { logEngagement } from "../helper/log.helper";
import Timer from "./Timer";

const Background = styled("div")(({ theme }) => ({
	background: "#fff", // Replace with your image path
	backgroundSize: "cover",
	padding: "50px",
	minHeight: "400px",
	display: "flex",
	justifyContent: "center",
	borderRadius: "15px",
	[theme.breakpoints.down('sm')]: {
		padding: "50px 8px", // equivalent to 3 * 8px = 24px, adjust as needed
	},
	[theme.breakpoints.up('sm')]: {
		padding: theme.spacing(6), // equivalent to 6 * 8px = 48px, adjust as needed
	},
}));

const Footprint = styled(DirectionsWalkIcon)(({ theme }) => ({
	fontSize: "2rem",
	margin: "0 5px",
	opacity: 0.7,
	transition: "color 0.5s ease-in-out",
}));

const StyledCard = styled(Card)({
	marginBottom: "20px",
	backgroundColor: "rgba(255, 255, 255, 0.8)",
	transition: "transform 0.5s ease-in-out",
	maxWidth: "500px",
	borderWidth: "2px",
	"&:hover": {
		transform: "scale(1.05)",
	},
});
const ClueContainer = styled(Box)({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
});

function PoirotGame({
	symptoms,
	conditions,
	displayTime,
	faildMessage,
	successMessage,
	moreUrl,
	challengeId,
	onFinished,
}) {

	const [currentClueIndex, setCurrentClueIndex] = useState(0);
	const [guessedCondition, setGuessedCondition] = useState("");
	const [score, setScore] = useState(0);
	const [gameOver, setGameOver] = useState(false);
	const [showClue, setShowClue] = useState(true);
	const [loading, setLoading] = useState(false);
	const [activeFootprint, setActiveFootprint] = useState(0);
	const [seconds, setSeconds] = useState(0);

	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const npi = searchParams.get("npi");
	const utmSource = searchParams.get("utm_source");
	const utmMedium = searchParams.get("utm_medium");

	const ipAddress = useSelector((state) => state.ipAddress);

	useEffect(() => {
		if (!gameOver) {
			const timer = setTimeout(() => {
				if (currentClueIndex < symptoms.length - 1) {
					setShowClue(false);
					setLoading(true);
					setTimeout(() => {
						setCurrentClueIndex(currentClueIndex + 1);
						setShowClue(true);
						setLoading(false);
					}, 500);
				} else {
					setGameOver(true);
				}
			}, displayTime * 1000);
			return () => clearTimeout(timer);
		}
	}, [currentClueIndex, gameOver]);

	useEffect(() => {
		setSeconds(0);
		const footprintTimer = setInterval(() => {
			setSeconds((prev) => prev + 1);
		}, 1000); // 10 seconds / 3 footprints = 3333ms per footprint

		return () => clearInterval(footprintTimer);
	}, [currentClueIndex]);

	const handleGuess = () => {
		if (conditions === guessedCondition) {
			setScore(score + symptoms[currentClueIndex].points);
			setGameOver(true);
			onFinished();
		} else if (currentClueIndex < symptoms.length - 1) {
			setShowClue(false);
			setLoading(true);
			setTimeout(() => {
				setCurrentClueIndex(currentClueIndex + 1);
				setShowClue(true);
				setLoading(false);
			}, 500);
		} else {
			setGameOver(true);
			onFinished();
		}
	};

	const handleNavigation = (url) => {
		if (url) {
			let payload = {
				npi: npi,
				ipAddress: ipAddress,
				engagementRefId: "Message Page",
				engagementSecRefId: challengeId,
				engagementType: "Clicked CTA",
				utmSource: utmSource,
				utmMedium: utmMedium,
				timeToComplete: "",
				gameResult: "",
			};
			logEngagement(payload);

			if (!url.startsWith("http://") && !url.startsWith("https://")) {
				url = "http://" + url; // Default to http if no protocol is specified
			}

			window.open(url, "_blank", "noopener,noreferrer");
		}
	};

	return (
		<Background>
			<Container>
				{!gameOver ? (
					<ClueContainer>
						<Fade sx={{ width: "100%" }} in={showClue}>
							<Box
								display="flex"
								flexDirection={{
									md: "row",
									sm: "column",
									xs: "column",
								}}
								justifyContent="space-between"
								alignItems="center"
								gap={{ md: 7, sm: 5, xs: 3 }}
								position="relative"
							>
								<Box position="absolute" top={{ md: "-30px", xs: "-50px" }} right={{ md: "-50px", xs: "-30px" }}>
									<Timer displayTime={displayTime} seconds={seconds} />
								</Box>
								<Box
									sx={{
										mt: 1,
										width: { md: "40%", sm: "100%", xs: "100%" },
									}}
								>
									<Box
										component="img"
										src={`${process.env.REACT_APP_API_IMAGE_URL +
											symptoms[currentClueIndex].image
											}`}
										sx={{ width: "100%" }}
									></Box>
								</Box>
								<Box
									sx={{
										width: { md: "53%", sm: "100%", xs: "100%" },
									}}
								>
									<Typography
										variant="body1"
										fontSize={{ md: 30, sm: 28, xs: 26 }}
										fontWeight={700}
										color={"rgb(113, 201, 240)"}
									>
										Clue #{currentClueIndex + 1}:
									</Typography>
									<Typography
										variant="body1"
										fontSize={{ md: 22, sm: 20, xs: 18 }}
										fontWeight={700}
										mt={2}
									>
										{symptoms[currentClueIndex].clue}
									</Typography>
									<Box
										mt={4}
										width="100%"
										sx={{
											display: "flex",
											justifyContent: "space-between",
											gap: 3,
										}}
									>
										<InputBase
											variant="outlined"
											placeholder="Answer"
											value={guessedCondition}
											onChange={(e) => setGuessedCondition(e.target.value)}
											sx={{
												border: "2px solid #001232",
												borderRadius: "5px",
												padding: "3px 10px",
												color: "#000",
												flex: 1,
											}}
										/>
										<Button
											onClick={handleGuess}
											sx={{
												bgcolor: "#001232",
												color: "#fff",
												px: 3,
												borderRadius: 1,
												"&:hover": { bgcolor: "#002232" },
											}}
										>
											SUBMIT
										</Button>
									</Box>
								</Box>
							</Box>
							{/* <StyledCard variant="outlined">
                <CardContent>
                  <AvatarContainer>
                    <CardMedia
                      component="img"
                      height="100"
                      sx={{ width: "auto" }}
                      image={
                        process.env.REACT_APP_API_IMAGE_URL +
                        symptoms[currentClueIndex].image
                      }
                      alt="Card Image"
                    />
                    <Typography variant="body1" ml={2} fontSize={20}>
                      {symptoms[currentClueIndex].clue}
                    </Typography>
                  </AvatarContainer>
                </CardContent>
              </StyledCard> */}
						</Fade>
						{/* <Box display="flex" justifyContent="center" mb={2}>
              {[0, 1, 2].map((index) => (
                <Footprint
                  key={index}
                  className={`footprint ${
                    activeFootprint === index ? "active" : ""
                  }`}
                />
              ))}
            </Box> */}
						{loading && <CircularProgress style={{ marginTop: "20px" }} />}
					</ClueContainer>
				) : (
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							flexDirection: "column",
							alignItems: "center",
							gap: 3,
						}}
					>
						<Box
							sx={{
								textAlign: "center",
								position: "relative",
							}}
						>
							<Typography
								sx={{
									fontSize: { md: "42px", sm: "38px", xs: "32px" },
									fontWeight: 700,
									color: score === 0 ? "#FF0000" : "rgb(113, 201, 240)",
								}}
							>
								{score === 0 ? "TIME's UP!" : "GREAT JOB!"}
							</Typography>
							<Typography
								sx={{
									fontSize: "22px",
									fontWeight: 700,
									color: "#333",
									textAlign: "center",
								}}
							>
								{score === 0
									? "Unfortunately, you did not guess it in time."
									: `You scored ${score} points. You and 30% of your peers answered this correctly.`}
							</Typography>
							{score !== 0 ? (
								<Typography
									sx={{
										fontSize: "20px",
										fontWeight: 400,
										color: "#333",
										textAlign: "center",
										mt: "20px",
									}}
									dangerouslySetInnerHTML={{
										__html:
											successMessage && successMessage !== ""
												? successMessage
												: "Great job! You passed the game in time.",
									}}
								></Typography>
							) : (
								<Typography
									sx={{
										fontSize: "20px",
										fontWeight: 400,
										color: "#333",
										textAlign: "center",
										mt: "20px",
									}}
									dangerouslySetInnerHTML={{
										__html:
											faildMessage && faildMessage !== ""
												? faildMessage
												: "Unfortunately, you did not pass it in time.",
									}}
								></Typography>
							)}
							<Button
								variant="contained"
								size="medium"
								color="success"
								sx={{
									backgroundColor: "#A2D049",
									fontSize: "20px",
									fontWeight: 600,
									marginTop: "30px",
									color: "#021637",
									width: "250px",
									py: 1,
									"&:hover": {
										bgcolor: "#bcf154",
									},
								}}
								onClick={() => handleNavigation(moreUrl)}
							>
								Learn More
							</Button>
						</Box>
					</Box>
				)}
			</Container>
		</Background>
	);
}

export default PoirotGame;
