import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
	Container,
	Box,
	Typography,
	Snackbar,
	Alert,
	Link,
	Stack,
	IconButton,
} from "@mui/material";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";

import ISISection from "../components/ISISection";

import {
	getProgramByName,
	getPacksAndChallengesByProgramId,
} from "../helper/program.helper";
import { logEngagement } from "../helper/log.helper";

import AdBanner from "../components/AdBanner";
import ChallengePack from "../components/ChallengePack";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import LoadingSpinner from "../components/LoadingSpinner";

function decodeHtml(html) {
	var txt = document.createElement("textarea");
	txt.innerHTML = html;
	return txt.value;
}

const Program = () => {
	const [open, setOpen] = useState(false);
	const [response, setResponse] = useState({
		status: false,
		message: "",
	});

	const [program, setProgram] = useState();
	const [programData, setProgramData] = useState([]);
	const { programName } = useParams();
	const location = useLocation();
	const navigate = useNavigate();

	const searchParams = new URLSearchParams(location.search);
	const npi = searchParams.get("npi");
	const utmSource = searchParams.get("utm_source");
	const utmMedium = searchParams.get("utm_medium");

	const settings = useSelector((state) => state.siteSettings);
	const ipAddress = useSelector((state) => state.ipAddress);
	const [loading, setLoading] = useState(true);


	useEffect(() => {
		if (ipAddress !== "" && program) {
			const payload = {
				npi: npi,
				ipAddress: ipAddress,
				engagementRefId: "Program",
				engagementSecRefId: program.id,
				engagementType: "Page View",
				utmSource: utmSource,
				utmMedium: utmMedium,
				timeToComplete: "",
				gameResult: "",
			};
			logEngagement(payload);
		}
	}, [ipAddress, program]);

	useEffect(() => {
		const decodedProgramName = programName?.replace(/-/g, " ");
		console.log(decodedProgramName);
		getProgramByName(decodedProgramName).then((data) => {
			if (data.program) {
				setProgram(data.program);
				getPacksAndChallengesByProgramId(data.program.id).then((res) => {
					setProgramData(res.data);
				});
			} else {
				navigate("not-found");
			}
		}).finally(() => {
			setLoading(false);
		});
	}, [programName]);

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setOpen(false);
	};


	if (loading) {
		return <LoadingSpinner message='Loading Program...' />;
	}

	return (
		<>
			{program && (program.is_active === "1" || program.is_active === 1) ? (
				<Container
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						gap: { xs: 2, sm: 3 },
						py: { xs: 0, sm: 0 },
						mb: 8,
						px: { xs: 0, sm: 0 },
						textAlign: { sm: "center", md: "left" },
						maxWidth: "unset !important",
					}}
				>
					<Snackbar
						anchorOrigin={{ vertical: "top", horizontal: "right" }}
						open={open}
						autoHideDuration={4000}
						onClose={handleClose}
					>
						<Alert
							onClose={handleClose}
							severity={response.status ? "success" : "error"}
							sx={{ width: "100%" }}
						>
							{response.message}
						</Alert>
					</Snackbar>
					{/* <Hero
          logo={`${
            settings && settings.site_logo !== ""
              ? process.env.REACT_APP_API_IMAGE_URL + settings.site_logo
              : "/assets/images/POCN_Logo_TM_285x120.png"
          }`}
          banner={
            program && program.image && program.image !== ""
              ? process.env.REACT_APP_API_IMAGE_URL + program.image
              : "/assets/images/banner.png"
          }
          tagLine={
            (program && program.tag_line) ||
            (settings && settings.site_tag_line) ||
            "Knowledge On the Go!"
          }
          tagColor={
            (program && program.tag_bar_color) ||
            (settings && settings.tag_bar_color) ||
            "#001B32"
          }
        /> */}
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							gap: { xs: 2, sm: 3 },
							py: { xs: 0 },
							px: { xs: 0 },
							textAlign: { sm: "center", md: "left" },
							width: "100%",
						}}
						id="main"
					>
						<Box
							width={"100%"}
							px={{ xs: 2, md: 6 }}
							py={2}
							borderBottom={"1px solid #707070"}
						>
							{/* <Box
                component={"img"}
                src={
                  program && program.image && program.image !== ""
                    ? program.image
                    : settings && settings.site_logo !== ""
                    ? process.env.REACT_APP_API_IMAGE_URL + settings.site_logo
                    : "/assets/images/POCN_Logo_TM_285x120.png"
                }
              ></Box> */}
							<Box
								component={"img"}
								src={
									settings && settings.site_logo !== ""
										? process.env.REACT_APP_API_IMAGE_URL + settings.site_logo
										: "/assets/images/POCN_Logo_TM_285x120.png"
								}
							></Box>
						</Box>
						<Box
							width="100%"
							px={{ xs: 2, md: 6 }}
							py={{ xs: 2, md: 0 }}
							display="flex"
							flexDirection="column"
							gap={3}
							alignItems="center"
						>
							<Box display={{ sm: "flex", xs: "flex" }}
								flexDirection={{ md: "row", xs: "column" }}
								justifyContent={{ sm: "center" }}>
								<Link
									href={program && program.pi_link ? program.pi_link : "#"}
									target="_blank"
									sx={{
										color: `${program && program.pl_color && program.pl_color !== ""
											? program.pl_color
											: "#4933DA"
											}`,
										fontWeight: 700,
										fontFamily: "'Poppins'",
										pr: 1,
										borderRight: {
											md: `1px solid ${program && program.pl_color && program.pl_color !== ""
												? program.pl_color
												: "#4933DA"
												}`,
											sm: "none",
										},
										textDecoration: "underline",
									}}
								>
									US Full Prescribing Information
								</Link>
								<Link
									href={program && program.isi_link ? program.isi_link : "#"}
									target="_blank"
									sx={{
										color: `${program && program.pl_color && program.pl_color !== ""
											? program.pl_color
											: "#4933DA"
											}`,
										fontWeight: 700,
										fontFamily: "'Poppins'",
										ml: 1,
										textDecoration: "underline",
									}}
								>
									Important Safety Information
								</Link>
							</Box>
							{program && program.brand_logo && (
								<Box
									component={"img"}
									src={process.env.REACT_APP_API_IMAGE_URL + program.brand_logo}
									sx={{ mt: -1 }}
								></Box>
							)}
							{program && program.indication_info && (
								<Box
									border={`1px solid ${program.indication_color
										? program.indication_color
										: "#4933DA"
										}`}
									px={2}
									py={2}
									borderRadius={2}
								>
									<Typography
										sx={{
											color: "#4933DA",
											textAlign: "left",
											fontSize: { xs: "14px", md: "16px" },
											px: { xs: 0, md: 6 },
										}}
										dangerouslySetInnerHTML={{
											__html: decodeHtml(program.indication_info),
										}}
									/>
								</Box>
							)}
						</Box>
					</Box>
					{/* {program && program.banner_ad_id !== 0 && <AdBanner />} */}
					<Box
						sx={{
							px: { xs: 1, md: 0 },
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							width: "100%",
							pb: 6,
						}}
					>
						{program &&
							program.show_description === "1" &&
							program.description ? (
							<div
								dangerouslySetInnerHTML={{
									__html: decodeHtml(program.description),
								}}
							/>
						) : (
							<>
								<Typography
									sx={{
										fontSize: { xs: "18px", md: "32px" },
										fontWeight: 700,
										color: "#333",
										textAlign: "center",
									}}
								>
									Take the Program Challenge!
								</Typography>
								<Typography
									sx={{
										fontSize: { xs: "16px", md: "18px" },
										fontWeight: 500,
										color: "#d11717",
										textAlign: "center",
									}}
								>
									How much do you know about the Program?
								</Typography>
							</>
						)}
						<Box
							sx={{
								px: { xs: 0 },
								mt: 2,
								display: "flex",
								flexDirection: "column",
								gap: { xs: 2, md: 4 },
								width: "100%",
							}}
						>
							{programData.map((data, index) => {
								return (
									<ChallengePack
										programName={program.name}
										programImage={program.image}
										data={data}
										key={index}
									/>
								);
							})}
						</Box>
					</Box>

					{program && program.isi && (
						<ISISection
							isiText={program.isi}
							isSticky={program.stiky_isi === "1"}
						/>
					)}
					<Box
						display="flex"
						flexDirection="column"
						alignItems="center"
						gap={2}
						width="100%"
						px={{ xs: 2, md: 6 }}
						py={5}
					>
						{program && program.company_logo && (
							<Box
								component="img"
								src={process.env.REACT_APP_API_IMAGE_URL + program.company_logo}
								alt="logo of sitemark"
							></Box>
						)}
						{program && program.company_info && (
							<Typography
								sx={{ textAlign: "center", width: "50%" }}
								dangerouslySetInnerHTML={{
									__html: decodeHtml(program.company_info),
								}}
							></Typography>
						)}
						{program && (program.legal_notice_link || program.pp_link) && (
							<Box>
								{program.legal_notice_link && (
									<Link
										href={program.legal_notice_link}
										target="_blank"
										sx={{
											color: program.ln_pp_color
												? program.ln_pp_color
												: "#DA00B5",
											pr: 2,
											borderRight: `${program.pp_link ? "1px solid #4933DA" : "none"
												}`,
										}}
									>
										Legal Notice
									</Link>
								)}
								{program.pp_link && (
									<Link
										href={program.pp_link}
										target="_blank"
										sx={{
											color: program.ln_pp_color
												? program.ln_pp_color
												: "#DA00B5",
											ml: 2,
										}}
									>
										Privacy Policy
									</Link>
								)}
							</Box>
						)}
					</Box>
					<Container
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							gap: { xs: 4, sm: 8 },
							py: { xs: 3, sm: 4 },
							px: { xs: 2, md: 6 },
							textAlign: { sm: "center", md: "left" },
							bgcolor: "#fff",
							borderTop: "1px solid #707070",
							maxWidth: "unset !important",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: { md: "row", sm: "column", xs: "column" },
								gap: { md: 5, sm: 4, xs: 4 },
								width: "100%",
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: { md: "flex-start", sm: "center", xs: "center" },
									gap: 4,
									width: { md: "45%", sm: "100%" },
								}}
							>
								<Box
									component="img"
									src={`${settings && settings.footer_logo
										? process.env.REACT_APP_API_IMAGE_URL +
										settings.footer_logo
										: "/assets/images/POCN_Logo-White_TM_285x120.png"
										}`}
									alt="logo of sitemark"
								></Box>
							</Box>
							<Box
								sx={{
									display: "flex",
									flexDirection: { md: "row", sm: "column", xs: "column" },
									gap: { md: 5, sm: 3, xs: 1 },
									width: { md: "50%", sm: "100%", xs: "100%" },
									justifyContent: "space-between",
								}}
							>
								<Box display="flex" flexDirection="column" gap={1}>
									<Link
										href={
											settings && settings.privacy_policy_link
												? settings.privacy_policy_link
												: "#"
										}
										target="_blank"
										sx={{ color: "#001232" }}
									>
										Privacy Policy
									</Link>
									<Link
										href={
											settings && settings.terms_link
												? settings.terms_link
												: "#"
										}
										target="_blank"
										sx={{ color: "#001232" }}
									>
										Terms of Use
									</Link>
								</Box>
								<Box display="flex" flexDirection="column" gap={1}>
									<Link
										href={
											settings && settings.contact_us_link
												? settings.contact_us_link
												: "#"
										}
										target="_blank"
										sx={{ color: "#001232" }}
									>
										Contact Us
									</Link>
									<Link
										href={
											settings && settings.learning_hub_link
												? settings.learning_hub_link
												: "#"
										}
										target="_blank"
										sx={{ color: "#001232" }}
									>
										Learning Hub
									</Link>
								</Box>
								<Box display="flex" flexDirection="column" gap={1}>
									<Link
										href={
											settings && settings.pocn_plus_link
												? settings.pocn_plus_link
												: "#"
										}
										target="_blank"
										sx={{ color: "#001232" }}
									>
										POCN+
									</Link>
									<Link
										href={
											settings && settings.mentor_program_link
												? settings.mentor_program_link
												: "#"
										}
										target="_blank"
										sx={{ color: "#001232" }}
									>
										Mentor Program
									</Link>
								</Box>
								<Box display="flex" flexDirection="column" gap={1}>
									<Link
										href={`${settings && settings.be_a_leader_link
											? settings.be_a_leader_link
											: "https://www.pocn.com/ambassador-program/"
											}`}
										target="_blank"
										sx={{ color: "#001232" }}
									>
										Be a Leader
									</Link>
								</Box>
							</Box>
						</Box>
						<Box
							width="100%"
							py={{ xs: 1, md: 3 }}
							display="flex"
							flexDirection={{ md: "row", xs: "column-reverse" }}
							justifyContent="space-between"
							alignItems="center"
							borderTop="1px solid #707070"
						>
							<Typography
								sx={{
									fontSize: { md: "17px", sm: "16px", xs: "14px" },
									fontWeight: 400,
									color: "#414042",
									mt: { xs: 2, md: 0 },
									textAlign: { xs: "center", md: "left" },
								}}
							>
								{settings && settings.copyright_text
									? settings.copyright_text
									: "© 2024 Point Of Care Network, LLC. All Rights Reserved."}
							</Typography>
							<Stack
								direction="row"
								justifyContent="left"
								spacing={1}
								useFlexGap
								sx={{
									color: "text.secondary",
								}}
							>
								<IconButton
									color="inherit"
									href={`${settings && settings.facebook_link
										? settings.facebook_link
										: "https://facebook.com/POCNInc"
										}`}
									target="_blank"
									aria-label="facebook"
									sx={{ alignSelf: "center" }}
								>
									<FacebookRoundedIcon fontSize="medium" />
								</IconButton>
								<IconButton
									color="inherit"
									href={`${settings && settings.twitter_link
										? settings.twitter_link
										: "https://twitter.com/POCNInc"
										}`}
									target="_blank"
									aria-label="twitter"
									sx={{ alignSelf: "center" }}
								>
									<TwitterIcon fontSize="medium" />
								</IconButton>
								<IconButton
									color="inherit"
									href={`${settings && settings.linkedin_link
										? settings.linkedin_link
										: "https://www.linkedin.com/company/pocn/"
										}`}
									target="_blank"
									aria-label="linkedin"
									sx={{ alignSelf: "center" }}
								>
									<LinkedInIcon fontSize="medium" />
								</IconButton>
								<IconButton
									color="inherit"
									href={`${settings && settings.instagram_link
										? settings.instagram_link
										: "https://www.instagram.com/pocninc/"
										}`}
									target="_blank"
									aria-label="instagram"
									sx={{ alignSelf: "center" }}
								>
									<InstagramIcon fontSize="medium" />
								</IconButton>
							</Stack>
						</Box>
					</Container>
				</Container>
			) : (
				<Container
					component="main"
					maxWidth="xs"
					sx={{
						mt: 8,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Box sx={{ my: 5 }}>
						<Typography variant="h4" component="h1" gutterBottom>
							Inactive Page
						</Typography>
						<Typography variant="subtitle1">
							This page is currently under construction or not available for
							public view.
						</Typography>
					</Box>
				</Container>
			)}
		</>
	);
};

export default Program;
