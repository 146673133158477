import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Container, Typography, Button, Stack } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import QuizCorrect from "./QuizCorrect";
import { sendQuizAnswer } from "../helper/program.helper";
import { logEngagement } from "../helper/log.helper";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";

function decodeHtml(html) {
	var txt = document.createElement("textarea");
	txt.innerHTML = html;
	return txt.value;
}

export default function Quize({ challenge, onAnswered }) {
	const [step, setStep] = useState(0);
	const [quizzes, setQuizzes] = useState([]);
	const [answered, setAnswered] = useState(false);
	const [isCorrect, setIsCorrect] = useState(false);
	const [isLast, setIsLast] = useState(true);
	const [timeTaken, setTimeTaken] = useState(0);
	const [answerChoice, setAnswerChoice] = useState("");
	const [correctAnswer, setCorrectAnswer] = useState("");
	const [correctAnswersCount, setCorrectAnswerCount] = useState(0);
	const [resultPercent, setResultPercent] = useState(0);

	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const npi = searchParams.get("npi");
	const utmSource = searchParams.get("utm_source");
	const utmMedium = searchParams.get("utm_medium");

	const ipAddress = useSelector((state) => state.ipAddress);

	useEffect(() => {
		const interval = setInterval(() => {
			setTimeTaken((prevTime) => prevTime + 1);
		}, 1000);

		setQuizzes(
			challenge.quizzes ? JSON.parse(challenge.quizzes).reverse() : []
		);

		return () => clearInterval(interval);
	}, []);

	const handleSubmitAnswer = () => {
		sendQuizAnswer(challenge.id, answerChoice, step, ipAddress).then((res) => {
			setAnswered(true);
			onAnswered();
			setIsCorrect(res.evaluate);
			setCorrectAnswer(res.correctAnswer);

			if (res.evaluate) {
				setCorrectAnswerCount(correctAnswersCount + 1);
				setResultPercent(res.percent);
			}

			setIsLast(quizzes.length - 1 === step);

			let payload = {
				npi: npi,
				ipAddress: ipAddress,
				engagementRefId: "Challenge",
				engagementSecRefId: challenge.id,
				engagementType: "Challenge Completed",
				utmSource: utmSource,
				utmMedium: utmMedium,
				timeToComplete: timeTaken,
				gameResult: res.evaluate ? "Success" : "Failure",
			};
			logEngagement(payload);

			payload = {
				npi: npi,
				ipAddress: ipAddress,
				engagementRefId: "Message Page",
				engagementSecRefId: challenge.id,
				engagementType: "Page View",
				utmSource: utmSource,
				utmMedium: utmMedium,
				timeToComplete: "",
				gameResult: "",
			};
			logEngagement(payload);
		});
	};

	const handleNextCorrect = () => {
		setAnswered(false);
		setAnswerChoice("");
		setIsCorrect(false);
		setStep(step + 1);
	};

	const handleChangeChoice = (event) => {
		setAnswerChoice(event.target.value);
	};

	const handleNavigation = (url) => {
		if (url) {
			let payload = {
				npi: npi,
				ipAddress: ipAddress,
				engagementRefId: "Message Page",
				engagementSecRefId: challenge.id,
				engagementType: "Clicked CTA",
				utmSource: utmSource,
				utmMedium: utmMedium,
				timeToComplete: "",
				gameResult: "",
			};
			logEngagement(payload);

			if (!url.startsWith("http://") && !url.startsWith("https://")) {
				url = "http://" + url; // Default to http if no protocol is specified
			}

			window.open(url, "_blank", "noopener,noreferrer");
		}
	};

	return (
		<Box
			sx={{
				px: { md: 7, sm: 5, xs: 2 },
				py: { md: 4, sm: 3, xs: 2 },
				border: "2px solid #ccc",
				borderRadius: { md: 10, sm: 8, xs: 6 },
				bgcolor: "#fff",
				mt: { sm: 3, xs: 1 },
			}}
		>
			{isCorrect ? (
				<QuizCorrect
					challenegId={challenge.id}
					isLast={isLast}
					isSingle={quizzes.length === 1}
					isDisplayStats={challenge.display_results === "1"}
					successMessage={challenge.success_message}
					successImage={challenge.success_message_image}
					correctCount={correctAnswersCount}
					quizCount={quizzes.length}
					resultPercent={resultPercent}
					moreUrl={challenge.more_url}
					className={`quiz ${answered && isCorrect ? "fade-in" : "fade-out"}`}
					onNext={handleNextCorrect}
				/>
			) : (
				<>
					{quizzes.map((quiz, index) => {
						return (
							step === index && (
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										flexDirection: "column",
										alignItems: "center",
										gap: { md: 4, sm: 3, xs: 1 },
									}}
								// className={`quiz ${step === index ? "fade-in" : "fade-out"}`}
								>
									<Container
										sx={{
											px: { xs: 0 },
											display: "flex",
											flexDirection: "column",
											justifyContent: "space-between",
											gap: 2,
											pb: { md: 6, sm: 4, xs: 2 },
											borderBottom: "2px solid #001232",
										}}
									>
										{quizzes.length > 1 && (
											<Typography
												sx={{
													fontSize: "18px",
													fontWeight: 700,
													color: "#333",
													textAlign: "left",
												}}
											>
												Question {step + 1} of {quizzes.length}
											</Typography>
										)}

										{answered && (
											<Box
												sx={{
													textAlign: "center",
													position: "relative",
												}}
											>
												<Typography
													sx={{
														fontSize: { md: "45px", sm: "38px", xs: "28px" },
														fontWeight: 700,
														color: "#FF0000",
													}}
												>
													INCORRECT!
												</Typography>
											</Box>
										)}
										<Box
											display="flex"
											flexDirection={{
												md: "row",
												sm: "column",
												xs: "column",
											}}
											justifyContent="space-between"
											gap={{ md: 7, sm: 5, xs: 3 }}
										>
											<Box
												sx={{
													mt: 1,
													width: { md: "47%", sm: "100%", xs: "100%" },
												}}
											>
												<Box
													component="img"
													src={`${challenge && challenge.image
															? process.env.REACT_APP_API_IMAGE_URL +
															challenge.image
															: "/assets/images/Treating-Obesity.jpg"
														}`}
													sx={{ width: "100%" }}
												></Box>
											</Box>
											<Box
												sx={{
													width: { md: "50%", sm: "100%", xs: "100%" },
												}}
											>
												<Typography
													sx={{
														fontSize: { md: "24px", sm: "22px", xs: "20px" },
														fontWeight: 600,
														color: "#71C9F0",
														textAlign: { md: "left", xs: "left" },
													}}
													dangerouslySetInnerHTML={{
														__html: decodeHtml(quiz.quizQuestion),
													}}
												></Typography>
												<FormControl sx={{ mt: 2, width: "100%", ml: -2 }}>
													<RadioGroup
														aria-labelledby="demo-radio-buttons-group-label"
														defaultValue="female"
														name="radio-buttons-group"
														value={answerChoice}
														onChange={handleChangeChoice}
														sx={{ gap: { sm: 1, xs: 0 } }}
													>
														{quiz.answerChoice1 && (
															<Stack
																flexDirection="row"
																justifyContent="space-between"
																alignItems="center"
																width="100%"
																px={2}
																bgcolor={`${answered &&
																		answerChoice === quiz.answerChoice1
																		? "#ff4569"
																		: ""
																	}`}
																borderRadius={2}
															>
																<FormControlLabel
																	value={quiz.answerChoice1}
																	control={<Radio />}
																	label={quiz.answerChoice1}
																	disabled={
																		answered &&
																		answerChoice !== quiz.answerChoice1
																	}
																	sx={{
																		"& .MuiFormControlLabel-label": {
																			fontSize: {
																				md: "22px",
																				sm: "20px",
																				xs: "18px",
																			},
																			fontWeight: 600,
																			color:
																				answered &&
																					answerChoice === quiz.answerChoice1
																					? "#fff"
																					: "#001232",
																		},
																		"& .Mui-checked": {
																			color:
																				answered &&
																					answerChoice === quiz.answerChoice1
																					? "#fff"
																					: "#0959AA",
																		},
																	}}
																/>
																{answered &&
																	answerChoice === quiz.answerChoice1 && (
																		<CloseIcon
																			sx={{
																				color: "#fff",
																				fontSize: "28px",
																				fontWeight: 700,
																			}}
																		/>
																	)}
																{answered &&
																	quiz.answerChoice1 === correctAnswer && (
																		<DoneIcon
																			sx={{
																				color: "green",
																				fontSize: "28px",
																			}}
																		/>
																	)}
															</Stack>
														)}
														{quiz.answerChoice2 && (
															<Stack
																flexDirection="row"
																justifyContent="space-between"
																alignItems="center"
																width="100%"
																px={2}
																bgcolor={`${answered &&
																		answerChoice === quiz.answerChoice2
																		? "#ff4569"
																		: ""
																	}`}
																borderRadius={2}
															>
																<FormControlLabel
																	value={quiz.answerChoice2}
																	control={<Radio />}
																	label={quiz.answerChoice2}
																	sx={{
																		"& .MuiFormControlLabel-label": {
																			fontSize: {
																				md: "22px",
																				sm: "20px",
																				xs: "18px",
																			},
																			fontWeight: 600,
																			color:
																				answered &&
																					answerChoice === quiz.answerChoice2
																					? "#fff"
																					: "#001232",
																		},
																		"& .Mui-checked": {
																			color:
																				answered &&
																					answerChoice === quiz.answerChoice2
																					? "#fff"
																					: "#0959AA",
																		},
																	}}
																	disabled={
																		answered &&
																		answerChoice !== quiz.answerChoice2
																	}
																/>
																{answered &&
																	answerChoice === quiz.answerChoice2 && (
																		<CloseIcon
																			sx={{
																				color: "#fff",
																				fontSize: "28px",
																				fontWeight: 700,
																			}}
																		/>
																	)}
																{answered &&
																	quiz.answerChoice2 === correctAnswer && (
																		<DoneIcon
																			sx={{
																				color: "green",
																				fontSize: "28px",
																			}}
																		/>
																	)}
															</Stack>
														)}
														{quiz.answerChoice3 && (
															<Stack
																flexDirection="row"
																justifyContent="space-between"
																alignItems="center"
																width="100%"
																px={2}
																bgcolor={`${answered &&
																		answerChoice === quiz.answerChoice3
																		? "#ff4569"
																		: ""
																	}`}
																borderRadius={2}
															>
																<FormControlLabel
																	value={quiz.answerChoice3}
																	control={<Radio />}
																	label={quiz.answerChoice3}
																	disabled={
																		answered &&
																		answerChoice !== quiz.answerChoice3
																	}
																	sx={{
																		"& .MuiFormControlLabel-label": {
																			fontSize: {
																				md: "22px",
																				sm: "20px",
																				xs: "18px",
																			},
																			fontWeight: 600,
																			color:
																				answered &&
																					answerChoice === quiz.answerChoice3
																					? "#fff"
																					: "#001232",
																		},
																		"& .Mui-checked": {
																			color:
																				answered &&
																					answerChoice === quiz.answerChoice3
																					? "#fff"
																					: "#0959AA",
																		},
																	}}
																/>
																{answered &&
																	answerChoice === quiz.answerChoice3 && (
																		<CloseIcon
																			sx={{
																				color: "#fff",
																				fontSize: "28px",
																				fontWeight: 700,
																			}}
																		/>
																	)}
																{answered &&
																	quiz.answerChoice3 === correctAnswer && (
																		<DoneIcon
																			sx={{
																				color: "green",
																				fontSize: "28px",
																			}}
																		/>
																	)}
															</Stack>
														)}
														{quiz.answerChoice4 && (
															<Stack
																flexDirection="row"
																justifyContent="space-between"
																alignItems="center"
																width="100%"
																px={2}
																bgcolor={`${answered &&
																		answerChoice === quiz.answerChoice4
																		? "#ff4569"
																		: ""
																	}`}
																borderRadius={2}
															>
																<FormControlLabel
																	value={quiz.answerChoice4}
																	control={<Radio />}
																	label={quiz.answerChoice4}
																	disabled={
																		answered &&
																		answerChoice !== quiz.answerChoice4
																	}
																	sx={{
																		"& .MuiFormControlLabel-label": {
																			fontSize: {
																				md: "22px",
																				sm: "20px",
																				xs: "18px",
																			},
																			fontWeight: 600,
																			color:
																				answered &&
																					answerChoice === quiz.answerChoice4
																					? "#fff"
																					: "#001232",
																		},
																		"& .Mui-checked": {
																			color:
																				answered &&
																					answerChoice === quiz.answerChoice4
																					? "#fff"
																					: "#0959AA",
																		},
																	}}
																/>
																{answered &&
																	answerChoice === quiz.answerChoice4 && (
																		<CloseIcon
																			sx={{
																				color: "#fff",
																				fontSize: "28px",
																				fontWeight: 700,
																			}}
																		/>
																	)}
																{answered &&
																	quiz.answerChoice4 === correctAnswer && (
																		<DoneIcon
																			sx={{
																				color: "green",
																				fontSize: "28px",
																			}}
																		/>
																	)}
															</Stack>
														)}
														{quiz.answerChoice5 && (
															<Stack
																flexDirection="row"
																justifyContent="space-between"
																alignItems="center"
																width="100%"
																px={2}
																bgcolor={`${answered &&
																		answerChoice === quiz.answerChoice5
																		? "#ff4569"
																		: ""
																	}`}
																borderRadius={2}
															>
																<FormControlLabel
																	value={quiz.answerChoice5}
																	control={<Radio />}
																	label={quiz.answerChoice5}
																	disabled={
																		answered &&
																		answerChoice !== quiz.answerChoice5
																	}
																	sx={{
																		"& .MuiFormControlLabel-label": {
																			fontSize: {
																				md: "22px",
																				sm: "20px",
																				xs: "18px",
																			},
																			fontWeight: 600,
																			color:
																				answered &&
																					answerChoice === quiz.answerChoice5
																					? "#fff"
																					: "#001232",
																		},
																		"& .Mui-checked": {
																			color:
																				answered &&
																					answerChoice === quiz.answerChoice5
																					? "#fff"
																					: "#0959AA",
																		},
																	}}
																/>
																{answered &&
																	answerChoice === quiz.answerChoice5 && (
																		<CloseIcon
																			sx={{
																				color: "#fff",
																				fontSize: "28px",
																				fontWeight: 700,
																			}}
																		/>
																	)}
																{answered &&
																	quiz.answerChoice5 === correctAnswer && (
																		<DoneIcon
																			sx={{
																				color: "green",
																				fontSize: "28px",
																			}}
																		/>
																	)}
															</Stack>
														)}
													</RadioGroup>
												</FormControl>
											</Box>
										</Box>
										{answered && (
											<Box mt={2}>
												<Typography
													sx={{
														fontSize: "20px",
														fontWeight: 400,
														color: "#333",
														textAlign: "center",
													}}
													dangerouslySetInnerHTML={{
														__html:
															challenge.incorrect_message &&
																challenge.incorrect_message !== ""
																? decodeHtml(challenge.incorrect_message)
																: "Unfortunately you didn’t answer correctly but great news!",
													}}
												></Typography>
											</Box>
										)}
									</Container>
									{answered ? (
										step < quizzes.length - 1 ? (
											<Button
												variant="contained"
												size="medium"
												color="success"
												sx={{
													backgroundColor: "#A2D049",
													fontSize: { md: "20px", sm: "18px", xs: "16px" },
													fontWeight: 600,
													color: "#021637",
													width: "250px",
													py: 1,
													"&:hover": {
														bgcolor: "#bcf154",
													},
												}}
												onClick={handleNextCorrect}
											>
												Next
											</Button>
										) : (
											<Button
												variant="contained"
												size="medium"
												color="success"
												sx={{
													backgroundColor: "#A2D049",
													fontSize: { md: "20px", sm: "18px", xs: "16px" },
													fontWeight: 600,
													color: "#021637",
													width: "250px",
													py: 1,
													"&:hover": {
														bgcolor: "#bcf154",
													},
												}}
												onClick={() => handleNavigation(challenge.more_url)}
											>
												Learn More
											</Button>
										)
									) : (
										<Button
											variant="contained"
											size="large"
											color="success"
											sx={{
												backgroundColor: "#A2D049",
												fontSize: { md: "20px", sm: "18px", xs: "16px" },
												fontWeight: 700,
												color: "#021637",
												"&:hover": {
													bgcolor: "#bcf154",
												},
											}}
											disabled={answerChoice === ""}
											onClick={handleSubmitAnswer}
										>
											Submit Answer
										</Button>
									)}
								</Box>
							)
						);
					})}
				</>
			)}
			{/* {!answered ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: 4,
            mt: 2,
          }}
          // className={`quiz ${step === index ? "fade-in" : "fade-out"}`}
        >
          <Container
            sx={{
              px: { xs: 0 },
              display: "flex",
              flexDirection: { md: "row", sm: "column", xs: "column" },
              justifyContent: "space-between",
              gap: { md: 7, sm: 5, xs: 3 },
              pb: 4,
              borderBottom: "2px solid #001232",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: { md: "left", sm: "center" },
                gap: 4,
                flexWrap: "wrap",
                mt: 1,
                width: { md: "47%", sm: "100%", xs: "100%" },
              }}
            >
              <Box
                component="img"
                src="/assets/images/Treating-Obesity.jpg"
                sx={{ width: "100%", aspectRatio: "16 / 9" }}
              ></Box>
            </Box>
            <Box
              sx={{
                width: { md: "50%", sm: "100%", xs: "100%" },
              }}
            >
              <Typography
                sx={{
                  fontSize: "26px",
                  fontWeight: 600,
                  color: "#71C9F0",
                  textAlign: { md: "left", xs: "left" },
                }}
                dangerouslySetInnerHTML={{ __html: decodeHtml(challenge.quiz_question) }}
              ></Typography>
              <FormControl sx={{ mt: 3 }}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  value={answerChoice}
                  onChange={handleChangeChoice}
                  sx={{ gap: 2 }}
                >
                  {challenge.answer_choice_1 && (
                    <FormControlLabel
                      value={challenge.answer_choice_1}
                      control={<Radio />}
                      label={challenge.answer_choice_1}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "22px",
                          fontWeight: 600,
                          color: "#001232"
                        },
                      }}
                    />
                  )}
                  {challenge.answer_choice_2 && (
                    <FormControlLabel
                      value={challenge.answer_choice_2}
                      control={<Radio />}
                      label={challenge.answer_choice_2}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "22px",
                          fontWeight: 600,
                          color: "#001232"
                        },
                      }}
                    />
                  )}
                  {challenge.answer_choice_3 && (
                    <FormControlLabel
                      value={challenge.answer_choice_3}
                      control={<Radio />}
                      label={challenge.answer_choice_3}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "22px",
                          fontWeight: 600,
                          color: "#001232"
                        },
                      }}
                    />
                  )}
                  {challenge.answer_choice_4 && (
                    <FormControlLabel
                      value={challenge.answer_choice_4}
                      control={<Radio />}
                      label={challenge.answer_choice_4}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "22px",
                          fontWeight: 600,
                          color: "#001232"
                        },
                      }}
                    />
                  )}
                  {challenge.answer_choice_5 && (
                    <FormControlLabel
                      value={challenge.answer_choice_5}
                      control={<Radio />}
                      label={challenge.answer_choice_5}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "22px",
                          fontWeight: 600,
                          color: "#001232"
                        },
                      }}
                    />
                  )}
                </RadioGroup>
              </FormControl>
            </Box>
          </Container>
          <Button
            variant="contained"
            size="large"
            color="success"
            sx={{
              backgroundColor: "#A2D049",
              fontSize: "20px",
              fontWeight: 700,
              color: "#021637",
              "&:hover": {
                bgcolor: "#bcf154",
              }
            }}
            disabled={answerChoice === ""}
            onClick={handleSubmitAnswer}
          >
            Submit Answer
          </Button>
        </Box>
      ) : isCorrect ? (
        <QuizCorrect
          answer={answers[step]}
          isLast={isLast}
          isDisplayStats={challenge.display_results === 1}
          successMessage={challenge.success_message}
          successImage={challenge.success_message_image}
          correctCount={correctAnswersCount}
          moreUrl={challenge.more_url}
          className={`quiz ${answered && isCorrect ? "fade-in" : "fade-out"}`}
          onNext={handleNextCorrect}
        />
      ) : (
        <QuizIncorrect
          answer={answers[step]}
          isLast={isLast}
          incorrectMessage={challenge.incorrect_message}
          incorrectImage={challenge.incorrect_message_image}
          correctCount={correctAnswersCount}
          moreUrl={challenge.more_url}
          className={`quiz ${answered && !isCorrect ? "fade-in" : "fade-out"}`}
          onNext={handleNextCorrect}
        />
      )} */}
			{/* {quizes.length > 1 && (
        <Typography
          sx={{
            fontSize: { md: "18px", sm: "16px", xs: "16px" },
            fontWeight: 600,
            textAlign: { md: "left", xs: "left" },
          }}
        >
          Question {step + 1} of {quizes.length}
        </Typography>
      )}
      {!answered &&
        quizes.map((quiz, index) => {
          return (
            step === index && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 4,
                  mt: 2,
                }}
                className={`quiz ${step === index ? "fade-in" : "fade-out"}`}
              >
                <Container
                  sx={{
                    px: { xs: 0 },
                    display: "flex",
                    flexDirection: { md: "row", sm: "column", xs: "column" },
                    justifyContent: "space-between",
                    gap: { md: 7, sm: 5, xs: 3 },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: { md: "left", sm: "center" },
                      gap: 4,
                      flexWrap: "wrap",
                      mt: 1,
                      width: { md: "47%", sm: "100%", xs: "100%" },
                    }}
                  >
                    <Box
                      component="img"
                      src="/assets/images/Treating-Obesity.jpg"
                      sx={{ width: "100%", aspectRatio: "16 / 9" }}
                    ></Box>
                  </Box>
                  <Box
                    sx={{
                      width: { md: "50%", sm: "100%", xs: "100%" },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: 500,
                        textAlign: { md: "left", xs: "left" },
                      }}
                    >
                      Question Text: {quiz.question}
                    </Typography>
                    <FormControl sx={{ mt: 5 }}>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        value={answerChoice}
                        onChange={handleChangeChoice}
                      >
                        {quiz.answerChoices.map((answer, index) => {
                          return (
                            <FormControlLabel
                              value={answer}
                              control={<Radio />}
                              label={answer}
                              key={`radio-${index}`}
                            />
                          );
                        })}
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Container>
                <Button
                  variant="contained"
                  size="large"
                  color="success"
                  sx={{
                    backgroundColor: "#5fbeec",
                    fontSize: "20px",
                    color: "#fff",
                  }}
                  disabled={answerChoice === ""}
                  onClick={() => handleSubmitAnswer(quiz, index)}
                >
                  Submit Answer
                </Button>
              </Box>
            )
          );
        })}
      {answered &&
        (isCorrect ? (
          <QuizCorrect
            answer={answers[step]}
            isLast={isLast}
            correctCount={correctAnswersCount}
            className={`quiz ${answered && isCorrect ? "fade-in" : "fade-out"}`}
            onNext={handleNextCorrect}
          />
        ) : (
          <QuizIncorrect
            answer={answers[step]}
            isLast={isLast}
            correctCount={correctAnswersCount}
            className={`quiz ${
              answered && !isCorrect ? "fade-in" : "fade-out"
            }`}
            onNext={handleNextCorrect}
          />
        ))} */}
		</Box>
	);
}
