import React from "react";
import { Container, Typography } from "@mui/material";
import AdBanner from "../components/AdBanner";
import ChallengePack from "../components/ChallengePack";

const programData = {
	id: 1,
	name: "Program 1",
	categories: [
		{
			name: "Diagnosis",
			packs: [
				{
					id: 1,
					name: "Challenge 1",
					slug: "challenge-1",
				},
				{
					id: 2,
					name: "Challenge 2",
					slug: "challenge-2",
				},
				{
					id: 3,
					name: "Challenge 3",
					slug: "challenge-3",
				},
				{
					id: 4,
					name: "Challenge 4",
					slug: "challenge-4",
				},
			],
		},
		{
			name: "Treatment",
			packs: [
				{
					id: 1,
					name: "Challenge 1",
					slug: "challenge-1",
				},
				{
					id: 2,
					name: "Challenge 2",
					slug: "challenge-2",
				},
				{
					id: 3,
					name: "Challenge 3",
					slug: "challenge-3",
				},
				{
					id: 4,
					name: "Challenge 4",
					slug: "challenge-4",
				},
			],
		},
		{
			name: "Industry/Disease State/Specialty Knowledge",
			packs: [
				{
					id: 1,
					name: "Challenge 1",
					slug: "challenge-1",
				},
				{
					id: 2,
					name: "Challenge 2",
					slug: "challenge-2",
				},
				{
					id: 3,
					name: "Challenge 3",
					slug: "challenge-3",
				},
				{
					id: 4,
					name: "Challenge 4",
					slug: "challenge-4",
				},
			],
		},
		{
			name: "Clinical Data",
			packs: [
				{
					id: 1,
					name: "Challenge 1",
					slug: "challenge-1",
				},
				{
					id: 2,
					name: "Challenge 2",
					slug: "challenge-2",
				},
				{
					id: 3,
					name: "Challenge 3",
					slug: "challenge-3",
				},
				{
					id: 4,
					name: "Challenge 4",
					slug: "challenge-4",
				},
			],
		},
		{
			name: "MOA/MOD",
			packs: [
				{
					id: 1,
					name: "Challenge 1",
					slug: "challenge-1",
				},
				{
					id: 2,
					name: "Challenge 2",
					slug: "challenge-2",
				},
				{
					id: 3,
					name: "Challenge 3",
					slug: "challenge-3",
				},
				{
					id: 4,
					name: "Challenge 4",
					slug: "challenge-4",
				},
			],
		},
		{
			name: "Other General",
			packs: [
				{
					id: 1,
					name: "Challenge 1",
					slug: "challenge-1",
				},
				{
					id: 2,
					name: "Challenge 2",
					slug: "challenge-2",
				},
				{
					id: 3,
					name: "Challenge 3",
					slug: "challenge-3",
				},
				{
					id: 4,
					name: "Challenge 4",
					slug: "challenge-4",
				},
			],
		},
	],
};
const Home = () => {
	return (
		<Container
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				gap: { xs: 2, sm: 3 },
				py: { xs: 2, sm: 4 },
				px: { xs: 2, sm: 4 },
				textAlign: { sm: "center", md: "left" },
				maxWidth: "unset !important",
			}}
		>
			<AdBanner />
			<Container sx={{ px: { xs: 1, md: 0 } }}>
				<Typography
					sx={{
						fontSize: { xs: "18px", md: "32px" },
						fontWeight: 700,
						color: "#333",
						textAlign: "center",
					}}
				>
					Take the {programData.name} Challenge!
				</Typography>
				<Typography
					sx={{
						fontSize: { xs: "14px", md: "18px" },
						fontWeight: 500,
						color: "#d11717",
						textAlign: "center",
					}}
				>
					How much do you know about {programData.name}?
				</Typography>
				<Container sx={{ px: { xs: 0 }, display: "flex", flexDirection: "column", gap: 4 }}>
					{programData.categories.map((category, index) => {
						return <ChallengePack data={category} key={index} />;
					})}
				</Container>
			</Container>
		</Container>
	);
};

export default Home;
